// Uncomment if you want to use for DEV
export const ENV_CONFIG = {
  // Base Deployed URL
  DEPLOYED_ECP_URL:
    process.env.REACT_APP_DEPLOYED_ECP_URL ||
    'https://www.end-customer-portal.ecp.dev.epilot.io/login',

  // General
  STAGE: process.env.REACT_APP_STAGE || 'dev',

  // Api's
  CUSTOMER_PORTAL_API_URL:
    process.env.REACT_APP_CUSTOMER_PORTAL_API_URL ||
    'https://customer-portal-api.dev.sls.epilot.io',

  DESIGN_BUILDER_API_URL:
    process.env.REACT_APP_DESIGN_BUILDER_API_URL ||
    'https://design-builder-api.dev.sls.epilot.io/v1',

  METERING_API_URL:
    process.env.REACT_APP_METERING_API_URL ||
    'https://metering.dev.sls.epilot.io',

  ENTITY_WEB_SOCKET_URL:
    process.env.REACT_APP_WEB_SOCKET_URL || 'wss://ws-entity.dev.sls.epilot.io',

  // AWS
  AMPLIFY_REGION: 'eu-central-1',

  FILE_API_URL:
    process.env.REACT_APP_FILE_API_URL ?? 'https://file.dev.sls.epilot.io',

  IBAN_API_URL:
    process.env.REACT_APP_IBAN_API_URL ??
    'https://m1ijr3w9q8.execute-api.eu-central-1.amazonaws.com/dev/v1/integration/iban?iban=',

  DATADOG_CLIENT_TOKEN:
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN ??
    '01a74178-cb3c-4a5c-bbfb-5c855ee4214c',

  FULLSTORY_ORG_ID: process.env.REACT_APP_FULLSTORY_ORG_ID ?? 'o-1336-eu1'
}

// Uncomment if you want to use for STAGING
// export const ENV_CONFIG = {
//   // Base Deployed URL
//   DEPLOYED_ECP_URL:
//     process.env.REACT_APP_DEPLOYED_ECP_URL ||
//     'https://end-customer-portal.ecp.staging.epilot.io/login',

//   // General
//   STAGE: process.env.REACT_APP_STAGE || 'staging',

//   // Api's
//   CUSTOMER_PORTAL_API_URL:
//     process.env.REACT_APP_CUSTOMER_PORTAL_API_URL ||
//     'https://customer-portal-api.staging.sls.epilot.io',

//   DESIGN_BUILDER_API_URL:
//     process.env.REACT_APP_DESIGN_BUILDER_API_URL ||
//     'https://design-builder-api.staging.sls.epilot.io/v1',

//   METERING_API_URL:
//     process.env.REACT_APP_METERING_API_URL ||
//     'https://metering.staging.sls.epilot.io',

//   // AWS
//   AMPLIFY_REGION: 'eu-central-1',

//   FILE_API_URL:
//     process.env.REACT_APP_FILE_API_URL ?? 'https://file.staging.sls.epilot.io',

//   IBAN_API_URL:
//     process.env.REACT_APP_IBAN_API_URL ??
//     'https://svc-integration-iban-api.staging.sls.epilot.io/v1/integration/iban?iban=',

//   JOURNEY_URL:
//     process.env.REACT_APP_JOURNEY_URL ?? 'https://journey.staging.epilot.io/',

//   DATADOG_CLIENT_TOKEN:
//     process.env.REACT_APP_DATADOG_CLIENT_TOKEN ??
//     '01a74178-cb3c-4a5c-bbfb-5c855ee4214c'
// }

// Uncomment if you want to use for PRODUCTION
// export const ENV_CONFIG = {
//   // Base Deployed URL
//   DEPLOYED_ECP_URL:
//     process.env.REACT_APP_DEPLOYED_ECP_URL ||
//     'https://end-customer-portal.ecp.epilot.io/login',

//   // General
//   STAGE: process.env.REACT_APP_STAGE || 'prod',

//   // Api's
//   CUSTOMER_PORTAL_API_URL:
//     process.env.REACT_APP_CUSTOMER_PORTAL_API_URL ||
//     'https://customer-portal-api.sls.epilot.io',

//   DESIGN_BUILDER_API_URL:
//     process.env.REACT_APP_DESIGN_BUILDER_API_URL ||
//     'https://design-builder-api.sls.epilot.io/v1',

//   METERING_API_URL:
//     process.env.REACT_APP_METERING_API_URL ||
//     'https://metering.sls.epilot.io',

//   // AWS
//   AMPLIFY_REGION: 'eu-central-1',

//   FILE_API_URL:
//     process.env.REACT_APP_FILE_API_URL ?? 'https://file.sls.epilot.io',

//   IBAN_API_URL:
//     process.env.REACT_APP_IBAN_API_URL ??
//     'https://svc-integration-iban-api.sls.epilot.io/v1/integration/iban?iban=',

//   JOURNEY_URL: process.env.REACT_APP_JOURNEY_URL ?? 'https://journey.epilot.io/',

//
//   DATADOG_CLIENT_TOKEN:
//     process.env.REACT_APP_DATADOG_CLIENT_TOKEN ??
//     '01a74178-cb3c-4a5c-bbfb-5c855ee4214c'
// }
