import process from 'process/browser'
import ReactDOM from 'react-dom'

import CustomerApiClient from './api/CustomerApiClient'
import CommonUtils from './utils/CommonUtils'

const CANARY_BUNDLE = '/bundle.js'

Object.assign(globalThis, { process })

export const main = async () => {
  import('./Root').then(({ default: Root }) => {
    ReactDOM.render(<Root />, document.getElementById('root'))
  })

  // if not, check if we are in stable mode
  const portalConfig = await CustomerApiClient.getPortalConfigByDomain(
    CommonUtils.getEcpDomain()
  ).then((res) => res.data)

  const isCanary = portalConfig?.org_settings?.['canary']?.enabled

  const isOverridden =
    window['importMapOverrides'].getOverrideMap().imports['ecp'] ===
    CANARY_BUNDLE

  if (isCanary && !isOverridden) {
    window['importMapOverrides'].addOverride('ecp', CANARY_BUNDLE)
    window['importMapOverrides'].enableOverride('ecp')

    window.location.reload()
  }

  if (!isCanary && isOverridden) {
    window['importMapOverrides'].removeOverride('ecp')
    window['importMapOverrides'].disableOverride('ecp')

    window.location.reload()
  }
}

main()
